import styled from "styled-components";
import bgImage from "../../assets/images/home-new-banner.webp";
import homeBg from '../../assets/images/home-section.webp'
import agentMonthImg from "../../assets/images/agent_of_month.jpg";

export const HomeStyles = styled.header`
  background: url(${bgImage}) center bottom/cover no-repeat;
  /* min-height: 400px; */
  padding: 250px 0px;

  @media (max-width: 991px) {
    padding: 50px 0px;
  }
`;

export const HomeHeaderContainerStyles = styled.div`
  .search-title {
    border-radius: 0px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  .search-button {
    border-radius: 0px !important;
    border-top-right-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
  }

  .search-rooms {
    border-radius: 0px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  .search-toilets {
    border-radius: 0px;
  }
  .search-max-square {
    border-radius: 0px;
  }
  .search-min-square {
    border-radius: 0px;
  }
  .search-price-max {
    border-radius: 0px;
  }
  .search-price-min {
    border-radius: 0px;
    border-top-right-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
  }
`;

export const AgentOfMonthCard = styled.div`
  background: url(${agentMonthImg}) center center/cover no-repeat;
  width: 500px;
  height: 500px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  @media (max-width: 1024px) {
    width: 100%;
    height: 100%;
  }
`;

export const AgentImageContainer = styled.div`
  margin-top: 30px;
  margin-left: 30px;
  width: 300px;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;

  .agent-badge {
    width: 200px;
    height: 200px;
    position: absolute;
    top: calc(50% - 100px);
    left: calc(100% - 100px);
  }

  .agent-image {
    width: 280px;
    height: 280px;
  }

  @media (max-width: 578px) {
    width: 250px;
    height: 250px;

    .agent-badge {
      width: 150px;
      height: 150px;
      position: absolute;
      top: calc(50% - 75px);
      left: calc(100% - 75px);
    }

    .agent-image {
      width: 220px;
      height: 220px;
    }
  }
`;

export const AgentName = styled.div`
  margin-left: 30px;
  margin-top: 30px;

  @media (max-width: 578px) {
    margin-bottom: 20px;
  }
`;

export const BannerContainer = styled.div`
  @media (max-width: 1024px) {
    img {
      width: 100%;
    }
  }
`;
